<template>
  <div>
    <b-card class="mb-4">
      <div class="card mb-4">
        <div class="d-flex align-items-start">
          <div>
            <h4 class="card-title mb-1">
              {{ $t("USUARIOS.DADOS_PESSOAIS") }}
            </h4>
          </div>
        </div>
      </div>
      <div class="res-inputs">
        <input-text
          class="user-input"
          ref="nomeUsuario"
          :type="form.comercial ? 'email' : 'text'"
          v-model="form.nomeUsuario"
          :label="$t('GERAL.USUARIO')"
          required
        />
        <input-text
          class="user-input"
          ref="nome"
          v-model="form.nome"
          :label="$t('USUARIOS.NOME')"
          required
        />

        <input-text
          class="user-input"
          ref="nomeEmpresa"
          v-model="form.nomeEmpresa"
          :label="$t('USUARIOS.NOME_EMPRESA')"
          required
        />

        <input-text
          class="user-input"
          :disabled="this.id"
          ref="cnpj"
          v-model="form.cnpj"
          :label="$t('USUARIOS.CNPJ')"
          v-mask="['###.###.###-##', '##.###.###/####-##']"
          required
        />

        <input-text
          class="user-input"
          type="email"
          ref="email"
          v-model="form.email"
          :label="$t('USUARIOS.EMAIL')"
          required
        />

        <input-text
          class="user-input"
          ref="telefone"
          v-model="form.telefone"
          :label="$t('USUARIOS.TELEFONE')"
          v-mask="['(##) ####-####', '(##) #####-####']"
          required
        />

        <input-text
          class="user-input"
          ref="celular"
          v-model="form.celular"
          :label="$t('USUARIOS.CELULAR')"
          v-mask="['(##) ####-####', '(##) #####-####']"
          required
        />
      </div>

      <div class="card mb-4">
        <div class="d-flex align-items-start">
          <div>
            <h4 class="card-title mb-1">{{ $t("PERMISSOES.PERMISSAO") }}</h4>
          </div>
        </div>
      </div>
      <b-row>
        <b-col class="usuario-perfis" cols="12">
          <div
            class="usuario-perfil"
            v-for="(item, index) in perfis"
            :key="item.id"
          >
            <input-checkbox
              class="user-input"
              @onChange="() => changePermissions(item.id, index)"
              :text="item.nome"
              :value="item.active ? true : false"
            />
          </div>
        </b-col>
      </b-row>

      <div class="card mb-4 pad-top">
        <div class="d-flex align-items-start">
          <div>
            <h4 class="card-title mb-1">{{ $t("USUARIOS.COMERCIAL") }}</h4>
          </div>
        </div>
      </div>
      <div class="res-inputs">
        <input-checkbox
          class="user-input user-check-comercial"
          ref="comercial"
          switch
          v-model="form.comercial"
          :text="$t('USUARIOS.COMERCIAL')"
        />

        <input-text
          v-if="form.comercial"
          class="user-input"
          ref="codigoAgente"
          v-model="form.codigoAgente"
          :label="$t('USUARIOS.CODIGOAGENTE')"
          required
        />
      </div>

      <div>
        <div class="card mb-4 mt-4">
          <div class="d-flex align-items-start">
            <div>
              <h4 class="card-title mb-1">
                {{ $t("USUARIOS.DADOS_ACESSO") }}
              </h4>
            </div>
          </div>
        </div>

        <div class="res-inputs">
          <div>
            <input-text
              ref="senha"
              v-model="form.senha"
              type="password"
              :label="$t('GERAL.SENHA')"
              :minLength="6"
              required
            />
            <verifica-forca-senha
              embaixo
              :value="forcaSenha"
            ></verifica-forca-senha>
          </div>

          <input-text
            ref="confirmacaoSenha"
            v-model="form.confirmacaoSenha"
            type="password"
            :label="$t('GERAL.CONFIRMACAO_SENHA')"
            :minLength="6"
            :validate="validarSenha"
            required
          />

          <b-button
            v-if="id"
            variant="primary"
            class="btn-nova-senha"
            @click="alterarSenha"
            >{{ $t("GERAL.TROCAR_SENHA") }}</b-button
          >
        </div>
      </div>
      <b-row>
        <b-col cols="12" class="text-right">
          <b-button variant="primary" @click="salvar">{{
            $t("GERAL.SALVAR")
          }}</b-button>
        </b-col>
      </b-row>
    </b-card>
  </div>
</template>

<script>
import UsuarioService from "@/common/services/usuario/usuario.service";
import PermissoesService from "@/common/services/permissoes/permissoes.service";
import { START_LOADING, STOP_LOADING } from "@/store/Store";
import { Usuario } from "@/common/models/Usuario";
import InputText from "@/components/inputs/InputText";
import InputCheckbox from "@/components/inputs/InputCheckbox";
import helpers from "@/common/utils/helpers";
import { DEFINIR_BREADCRUMB } from "@/store/breadcrumbs.module";
import VerificaForcaSenha from "./components/VerificaForcaSenha.vue";

export default {
  name: "Formulario",
  components: {
    InputText,
    InputCheckbox,
    VerificaForcaSenha,
  },
  data() {
    return {
      id: this.$route.params.id,
      form: new Usuario({}),
      perfis: [],
      perfisIds: [],
      perfilSelecionado: "",
      perfisSelecionados: [],
      perfisDeletados: [],
      forcaSenha: "Vazio",
    };
  },
  mounted() {
    this.$store.dispatch(DEFINIR_BREADCRUMB, [
      { titulo: this.$t("MENU.USUARIOS") },
      {
        titulo: !this.id ? this.$t("GERAL.CADASTRAR") : this.$t("GERAL.EDITAR"),
      },
    ]);
    this.listarPerfil();
  },
  computed: {
    validarCpfCnpj() {
      return helpers.validarCpfCnpj(this.form.cnpj);
    },
    validarCep() {
      return helpers.validarCep(this.formEndereco.cep);
    },
    validarSenha() {
      return this.form.senha == this.form.confirmacaoSenha;
    },
    mostrarSenha() {
      return !this.id;
    },
  },
  watch: {
    // eslint-disable-next-line no-unused-vars
    "form.senha"(value) {
      this.verificarForcaDaSenha();
    },
  },
  methods: {
    verificarForcaDaSenha() {
      let senha = this.form.senha;
      UsuarioService.verificarForcaDaSenha(senha)
        .then((result) => {
          this.forcaSenha = result.data.forcaSenhaEnum;
        })
        .catch((err) => console.log(err));
    },
    changePermissions(id, index) {
      this.perfis[index].active = !this.perfis[index].active;

      this.$set(this.perfis, index, this.perfis[index]);

      if (
        (this.form && !this.form.perfisId) ||
        (this.form.perfisId && this.form.perfisId.length <= 0)
      ) {
        this.form.perfisId = [];
        this.form.perfisId.push(id);
        return;
      }

      if (this.form && this.form.perfisId) {
        for (let i = 0; i < this.form.perfisId.length; i++) {
          if (this.form.perfisId[i] == id) {
            this.$delete(this.form.perfisId, i);
            return;
          }

          if (!this.form.perfisId.includes(id)) {
            this.form.perfisId.push(id);
            return;
          }
        }
      }
    },
    listarPerfil() {
      this.$store.dispatch(START_LOADING);
      PermissoesService.listar()
        .then((result) => {
          let data = result.data.data;

          if (data.itens.length > 0) {
            data.itens.sort((a, b) => a.nome.localeCompare(b.nome));

            this.perfis = data.itens.map((item) => {
              return { id: item.id, nome: item.nome };
            });
            if (this.id) this.buscarPorId();
          }
        })
        .catch(() => {})
        .finally(() => {
          this.$store.dispatch(STOP_LOADING);
        });
    },
    editarPerfis(id) {
      let sendData = {
        usuarioId: id,
        perfisId: this.form.perfisId || [],
      };
      UsuarioService.editarPerfis(sendData)
        .then(() => {
          this.$router.push({ name: "usuarios" });
        })
        .catch(() => {})
        .finally(() => {
          this.$store.dispatch(STOP_LOADING);
        });
    },
    validarFormulario() {
      let arValidation = [];
      console.log("this.$refs", this.$refs);
      arValidation.push(this.$refs.telefone.valid());
      arValidation.push(this.$refs.celular.valid());
      arValidation.push(this.$refs.nomeUsuario.valid());
      arValidation.push(this.$refs.nomeEmpresa.valid());
      arValidation.push(this.$refs.cnpj.valid());
      arValidation.push(this.$refs.email.valid());

      return arValidation.filter((elem) => elem == false).length == 0;
    },
    alterarSenha() {
      if (!this.validarFormulario()) return;
      this.$store.dispatch(START_LOADING);
      this.form.usuarioId = this.id;
      UsuarioService.alterarSenha(this.form)
        .then(() => {})
        .catch(() => {})
        .finally(() => {
          this.$store.dispatch(STOP_LOADING);
        });
    },
    salvar() {
      if (!this.validarFormulario()) return;
      this.$store.dispatch(START_LOADING);
      this.form.endereco = this.formEndereco;

      UsuarioService.salvar(this.form)
        .then((result) => {
          if (result) {
            //this.editarPerfis(this.id ? this.id : result.data.data.aggregateId);
            this.$router.push({ name: "usuarios" });
          }
        })
        .catch(() => {})
        .finally(() => {
          this.$store.dispatch(STOP_LOADING);
        });
    },
    buscarPorId() {
      this.$store.dispatch(START_LOADING);

      UsuarioService.buscarPorId(this.id)
        .then((result) => {
          this.form = new Usuario({ ...result.data.data });
          let perfis = this.perfis;
          let perfisId = result.data.data.perfisId;

          for (let i = 0; i < perfisId.length; i++) {
            for (let b = 0; b < perfis.length; b++) {
              if (perfisId[i] == perfis[b].id) {
                perfis[b].active = true;
                perfis[b].inBase = true;
              }
            }
          }

          this.perfis = perfis;
        })
        .catch(() => {})
        .finally(() => {
          this.$store.dispatch(STOP_LOADING);
        });
    },
  },
};
</script>
<style>
.pad-top {
  padding-top: 15px;
}
.user-check-comercial {
  padding-top: 10px;
}
</style>